var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"loading":_vm.loadingTable,"headers":_vm.headers,"items":_vm.progress,"items-per-page":5,"pageCount":_vm.numberOfPagesPaginate,"page":_vm.currentPagePaginate,"server-items-length":_vm.totalStagesPaginate,"options":_vm.options,"footer-props":{
        itemsPerPageText: 'Itens por pagina',
        itemsPerPageOptions: [5, 10, 15],
        showFirstLastPage: true
      }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Avanço Físico")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","loading":_vm.loadingBtnNewPeriod,"disabled":_vm.loadingBtnNewPeriod,"rounded":"","min-width":"150"},on:{"click":function($event){return _vm.newPhysicalProgress()}}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" Novo Avanço ")],1)],1)]},proxy:true},{key:"item.description",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.cost.name)+" ")]}},{key:"item.period",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.date_start) + ' - ' + _vm.formatDate(item.date_end))+" ")]}},{key:"item.realized",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatMoney(item.realized_percentage || 0) + ' %')+" ")]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(item.last == 'last'),expression:"item.last == 'last'"}],staticClass:"mr-2",attrs:{"icon":"","color":"success"},on:{"click":function($event){return _vm.newPhysicalProgress(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-plus ")])],1)]}}],null,true)},[_c('span',[_vm._v("Adicionar Novo Período ")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [((_vm.getUser.user_type=='super_admin')|| item.last == 'last')?_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.editItem(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-pencil ")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Editar")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"icon":"","color":"secondary"},on:{"click":function($event){return _vm.viewItem(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-magnify ")])],1)]}}],null,true)},[_c('span',[_vm._v("Visualizar")])])]}},{key:"no-data",fn:function(){return [_vm._v(" Nenhum registro encontrado ")]},proxy:true}])})],1),_c('MsgDelete',{attrs:{"loading":_vm.loadingDelete,"message":"Deseja excluir ?"},on:{"confirm":function($event){return _vm.destroyItem()},"close":function($event){_vm.dialogDelete = false}},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }